import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Text, Title } from "./pool"

const Beach = ({ image_1, image_2, text_left, text_right, title }) => {
  return (
    <Container>
      <ImagesContainer>
        <Img fluid={image_1.fluid} alt={image_1.alt || "beach"} />
        <Img fluid={image_2.fluid} alt={image_2.alt || "cabana"} />
      </ImagesContainer>
      <Title>{title.text || ""}</Title>
      <TextContainer>
        <Text>{text_left.text}</Text>
        <Text dangerouslySetInnerHTML={{ __html: text_right.html }} />
      </TextContainer>
    </Container>
  )
}

export default Beach

const Container = styled.div`
  padding: 40px 0;
`
const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 0 40px;
  @media (max-width: 780px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
`
const TextContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 0 40px;
  p {
    padding-bottom: 40px;
  }
  @media (max-width: 780px) {
    grid-template-columns: 1fr;

    p {
      padding-bottom: 16px;
    }
  }
`
