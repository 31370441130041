import React from "react"
import styled from "styled-components"
import { withPreview } from "gatsby-source-prismic"

import Layout from "../layout/layout"
import PageHeader from "../pageHeader"
import SEO from "../seo"
import Pool from "../pool/pool"
import Beach from "../pool/beach"

import { usePoolPageData } from "../../hooks/usePoolPageData"

const PoolPage = ({ data: previewData }) => {
  const { uid, sections, bookData } = usePoolPageData(previewData)

  return (
    <Layout bookBannerData={bookData}>
      <Container>
        <SEO title={uid} />
        <PageHeader {...sections[0].primary} />
        <Pool items={sections[1].items} />
        <Beach {...sections[2].primary} />
      </Container>
    </Layout>
  )
}

export default withPreview(PoolPage)

const Container = styled.div`
  max-width: 80%;
  margin: 0 auto;
`
