import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const usePoolPageData = previewData => {
  const staticData = useStaticQuery(graphql`
    query PoolPage {
      prismicPoolPage {
        _previewable
        uid
        data {
          body {
            ... on PrismicPoolPageBodyHeader {
              slice_type
              primary {
                title {
                  html
                }
                text {
                  text
                  raw
                }
                tagline {
                  text
                }
              }
            }
            ... on PrismicPoolPageBodyPool {
              id
              slice_type
              items {
                image {
                  alt
                  fluid {
                    srcSet
                    src
                    aspectRatio
                    sizes
                  }
                  localFile {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                  url
                }
                text {
                  text
                }
                title {
                  text
                }
              }
            }
            ... on PrismicPoolPageBodyBeach {
              id
              slice_type
              primary {
                image_1 {
                  alt
                  fluid {
                    src
                    sizes
                    srcSet
                    aspectRatio
                  }
                }
                image_2 {
                  alt
                  fluid {
                    aspectRatio
                    sizes
                    src
                    srcSet
                  }
                }
                text_left {
                  text
                }
                text_right {
                  text
                  raw
                  html
                }
                title {
                  text
                }
              }
            }
            ... on PrismicPoolPageBodyBook {
              id
              slice_type
              primary {
                button_text
                link {
                  url
                  link_type
                }
                text {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  const mergedData: any = mergePrismicPreviewData({ staticData, previewData })
  const { uid, data } = mergedData.prismicPoolPage
  let bookData
  const sections = []
  data.body.map(section => {
    if (section.slice_type === "book") {
      return (bookData = section.primary)
    }
    return sections.push(section)
  })
  return {
    uid,
    sections,
    bookData,
  }
}
