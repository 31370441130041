import React from "react"
import styled from "styled-components"

import Img from "gatsby-image"

const Pool = props => {
  return (
    <Container>
      {props.items.map((pool, i) => {
        return <PoolItem key={i} {...pool} />
      })}
    </Container>
  )
}

export default Pool

const PoolItem = ({ image, text, title }) => {
  return (
    <PoolItemContainer>
      <Img fluid={image.fluid} alt={image.alt || "pool image"} />
      <Title>{title.text}</Title>
      <Text>{text.text}</Text>
    </PoolItemContainer>
  )
}
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 40px;
  padding: 40px 0;
  div:last-child {
    padding-top: 45%;
  }
  @media (max-width: 780px) {
    grid-template-columns: 1fr;
    div:last-child {
      padding-top: 0;
    }
  }
`
const PoolItemContainer = styled.div``

export const Title = styled.h2`
  font-family: "Moderat Bold", sans-serif;
  font-size: 2rem;
  color: var(--primary-text);
  margin: 2.2rem 0;
`

export const Text = styled.p`
  color: var(--primary-text);
  margin: 0 0 1rem 0;
  font-family: "Moderat Regular", sans-serif;
  line-height: 20pt;
  padding: 0 16px 0 0;
`
